<template>
  <div>
    <b-form-select v-model="value" :options="options" :disabled="disable" @change="$emit('setSelect', value)" size="lg"></b-form-select>
  </div>
</template>

<script>
export default {
  name: "VectorsSelect",
  data() {
    return {
      value: null,
      disable: false,
    }
  },
  async created() {
    const res = await this.axios.get('user/vectors')

    await this.$store.dispatch("setVectorSelect", res.data)
  },
  computed: {
    options() {
      return this.$store.state.vectorsSelect
    }
  }
}
</script>